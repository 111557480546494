import { getRedirectUrl } from "@99tech/shared-utils";
import { Box, Container, ContainerProps } from "@mui/material";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Bg } from "src/assets";
import { DEFAULT_ENTER_SITE_URL, ENTER_SITE_URL_MAP, RefCodeMap } from "src/main/constants";
import { useFbcid } from "src/main/hooks";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;

  const { fbcid } = useFbcid();
  const [params] = useSearchParams();

  const handleClickPage = useCallback(() => {
    const hostname = window.location.hostname.replace(/^www\./, "");
    const enterSiteUrl = ENTER_SITE_URL_MAP[hostname] ?? DEFAULT_ENTER_SITE_URL;
    const url = getRedirectUrl(enterSiteUrl, fbcid, params, RefCodeMap);
    window.open(url, "_blank");
  }, [fbcid, params]);

  return (
    <Box
      sx={styles.wrapper}
      onClick={handleClickPage}
    >
      <Container
        {...containerProps}
        sx={styles.root}
        maxWidth="sm"
      >
        {children}
      </Container>
    </Box>
  );
};

const styles = createStyles({
  wrapper: {
    cursor: "pointer",
  },
  root: {
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "-60px",
    boxShadow: `0px 0px 22px 5px rgba(0,0,0,0.37)`,

    backgroundColor: "#7ED340",
    minHeight: "100vh",
    // cursor: "pointer",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    px: 0,
  },
});

export default Content;
